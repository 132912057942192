import React from 'react';
import COLORS from '../../styles/colors';
import FONTS from '../../styles/fonts';
import SharedJourneyIcon from '../../icons/shared-journey-icon';
import CircleInCircle from '../../icons/circle-in-circle-red';

type BannerProps = {
  isJourneyLive: boolean;
};
const Banner = (props: BannerProps) => {
  const { isJourneyLive } = props;

  const headerStyle = {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1rem',
    backgroundColor: COLORS.wildSand,
    alignItems: 'center',
    display: 'flex'
  };

  const liveStatusStyle = {
    color: COLORS.lnerRed,
    fontFamily: FONTS.book.fontFamily,
    fontSize: '0.8rem',
    backgroundColor: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    padding: '0.1rem 0.5rem 0.0005rem',
    borderRadius: '0.6rem',
    marginLeft: '0.6rem'
  };

  const liveStatus = (
    <div css={liveStatusStyle}>
      <CircleInCircle />
      <div css={{ marginLeft: '0.15rem' }}>Live</div>
    </div>
  );

  const textStyle = {
    fontFamily: FONTS.bold.fontFamily,
    fontsize: '14px',
    color: COLORS.lnerRed,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.3rem'
  };

  return (
    <div css={headerStyle}>
      <SharedJourneyIcon />
      <div css={textStyle}>
        <div> Shared Journey</div>
        {isJourneyLive ? liveStatus : null}
      </div>
    </div>
  );
};

export default Banner;
