import React from 'react';
import { Global } from '@emotion/core';
import Header from 'components/header';
import { GLOBAL_STYLES } from 'styles/global-styles';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';

const LiveJourneyLayout = ({ children }: any) => {
  const layoutStyle = {
    margin: `0 auto`,
    maxWidth: 506,
    backgroundColor: COLORS.wildSand,
    fontFamily: FONTS.book.fontFamily
  };

  return (
    <>
      <Global styles={GLOBAL_STYLES} />
      <Header />
      <div css={layoutStyle}>
        <main>{children}</main>
      </div>
    </>
  );
};

export default LiveJourneyLayout;
