export enum JourneyLegType {
  TRAIN,
  TRANSFER
}

export enum TravelModeType {
  BUS,
  TRAIN,
  FERRY,
  WALKING,
  TAXI,
  HOVERCRAFT,
  TRAM,
  UNDERGROUND,
  CHANGE
}

export enum JourneyLegStatus {
  COMPLETED,
  LIVE,
  FUTURE
}

// May need to change 'time'
// to 'actualTime' and 'estimatedTime'
// (To deal with delays)
export type Station = {
  expectedTime: string;
  actualTime?: string;
  name: string;
};

export enum OnTimeStatus {
  ONTIME,
  CANCELLED,
  DELAYED
}

//Date string is only used before the journey is live, and is displayed in the Journey Summary
export type JourneyLeg = {
  status: JourneyLegStatus; // which leg to show as active in view.
  type: JourneyLegType;
  onTimeStatus: OnTimeStatus;
  provider: string;
  travelMode: TravelModeType;
  dateString: string;
  durationInMinutes: number;
  departureStation: Station;
  departurePlatform: string;
  callingPoints: Station[];
  arrivalStation: Station;
  seats?: string; // Same format as Figma: Coach C - 51 & 52
  train: string; // Same format as Figma: 09:00 to Brighton
};
