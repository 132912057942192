import React from 'react';
import { TravelModeType } from 'models/journey-leg';
import { getPathFromTravelMode } from './get-path-from-travel-mode';

type TransferTravelIconProps = {
  travelMode: TravelModeType;
  color: string;
};

export const TransferTravelIcon = (props: TransferTravelIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path fill={props.color} d={getPathFromTravelMode(props.travelMode)} />
      </g>
    </svg>
  );
};
