import { TravelModeType } from '../models/journey-leg';

export const travelTypeToString = (travelMode: TravelModeType): string => {
  switch (travelMode) {
    case TravelModeType.BUS:
      return 'Bus';
    case TravelModeType.TRAIN:
      return 'Train';
    case TravelModeType.FERRY:
      return 'Ferry';
    case TravelModeType.WALKING:
      return 'Walk';
    case TravelModeType.TAXI:
      return 'Taxi';
    case TravelModeType.HOVERCRAFT:
      return 'Hovercraft';
    case TravelModeType.TRAM:
      return 'Tram';
    case TravelModeType.UNDERGROUND:
      return 'Underground';
    case TravelModeType.CHANGE:
      return 'Change';
  }
};
