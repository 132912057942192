import React from 'react';
import COLORS from '../styles/colors';
import '../styles/font-face.css';

const CircleInCircle: React.FC = () => (
  <svg
    width="12px"
    height="12px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill={COLORS.lnerRed}
    preserveAspectRatio="xMidYMid meet"
  >
    <title>Circle in Cirle</title>
    <g data-name="Circle in Circle">
      <path
        fillRule="evenodd"
        fill={COLORS.lnerRed}
        d="M5,0c2.761,0 5,2.239 5,5s-2.239,5 -5,5 -5,-2.239 -5,-5 2.239,-5 5,-5zM5,1C2.79,1 1,2.79 1,5s1.79,4 4,4 4,-1.79 4,-4 -1.79,-4 -4,-4zM5,2c1.657,0 3,1.343 3,3S6.657,8 5,8 2,6.657 2,5s1.343,-3 3,-3z"
      ></path>
    </g>
  </svg>
);

export default CircleInCircle;
