import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

import LoadingSpinner from 'components/loading-spinner';
import Banner from 'components/live-journey/banner';
import JourneySummary from 'components/live-journey/journey-summary';
import LiveJourneyLayout from 'components/live-journey/live-journey-layout';

import { useApiBaseUrl } from 'helpers/url-hooks';
import { formatMinutesToHours } from 'helpers/minutes-to-hours';

import { JourneyLeg, JourneyLegStatus } from 'models/journey-leg';

import COLORS from 'styles/colors';
import JourneyLegs from 'components/live-journey/journey-legs';

import liveJourneyMockData from '_mockdata/live-journey-mock-data';

enum PageState {
  LOADING,
  LOADED,
  API_ERROR,
  URL_ERROR
}

const LiveJourney = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const [pageState, updatePageState] = useState(PageState.LOADING);
  const apiUrl = useApiBaseUrl();
  console.log(apiUrl);

  // Journey data
  const [journeyLegs, setJourneyLegs] = useState<JourneyLeg[]>([]);
  const [isJourneyLive, setIsJourneyLive] = useState(false);
  const [formattedTotalDuration, setFormattedTotalDuration] = useState('');

  useEffect(() => {
    // URL Params will be used as params for API request.
    // URL_ERROR if no params present.
    if (!location.search) {
      updatePageState(PageState.URL_ERROR);
      return;
    }
    getJourneyData();
  }, []);

  const getJourneyData = async () => {
    try {
      // Process URL params.
      urlSearchParams.forEach((value, key) => {
        console.log(key);
        console.log(value);
      });
      // const response = await fetch(apiUrl + 'v1/stations');
      // const data = await response.json();
      // Process data received from API
      // into format easily used by UI components.
      processJourneyData(liveJourneyMockData);
      updatePageState(PageState.LOADED);
    } catch (error) {
      updatePageState(PageState.API_ERROR);
    }
  };

  const processJourneyData = (data: JourneyLeg[]) => {
    let totalJourneyDurationInMinutes = 0;
    for (let journeyLeg of data) {
      if (journeyLeg.status === JourneyLegStatus.LIVE) {
        setIsJourneyLive(true);
      }
      totalJourneyDurationInMinutes += journeyLeg.durationInMinutes;
    }
    setFormattedTotalDuration(
      formatMinutesToHours(totalJourneyDurationInMinutes)
    );
    setJourneyLegs(data);
  };

  const urlErrorView = <div>Live Journey requires a valid ticket ID.</div>;
  const apiErrorView = <div>The API returned an error.</div>;

  const successfulLoadView = (
    <div css={{ userSelect: 'none' }}>
      <Banner isJourneyLive={isJourneyLive} />
      <div
        css={{
          backgroundColor: COLORS.white,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}
      >
        <JourneySummary
          isJourneyLive={isJourneyLive}
          formattedTotalDuration={formattedTotalDuration}
          journeyLegs={journeyLegs}
        />
        <JourneyLegs journeyLegs={journeyLegs} />
      </div>
    </div>
  );

  return (
    <LiveJourneyLayout>
      {pageState === PageState.LOADED ? (
        <>{successfulLoadView}</>
      ) : pageState === PageState.API_ERROR ? (
        apiErrorView
      ) : pageState === PageState.LOADING ? (
        <LoadingSpinner />
      ) : (
        urlErrorView
      )}
    </LiveJourneyLayout>
  );
};

export default LiveJourney;
