import React from 'react';
import {
  JourneyLeg,
  TravelModeType,
  JourneyLegStatus
} from '../../models/journey-leg';
import COLORS from '../../styles/colors';
import FONTS from '../../styles/fonts';
import { formatMinutesToHours } from '../../helpers/minutes-to-hours';
import { travelTypeToString } from '../../helpers/travel-type-to-string';
import { TransferTravelIcon } from '../../icons/transfer-leg-travel-icon';
import { TransferCircle, AnimationType } from './transfer-circle';
import CircleInCircle from '../../icons/circle-in-circle-red';

type TransferJourneyLegViewProps = {
  journeyLeg: JourneyLeg;
};

const TransferJourneyLegView = (props: TransferJourneyLegViewProps) => {
  const { journeyLeg } = props;

  const travelMode = journeyLeg.travelMode;

  const containerStyle = {
    display: 'flex',
    padding: '1rem',
    paddingTop: '1.4rem',
    backgroundColor: COLORS.wildSand
  };

  const durationStyle = {
    fontSize: '12px',
    lineHeight: '16px',
    color: COLORS.doveGray,
    display: 'flex'
  };

  //transfer icon graphic

  const circleStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '8px',
    width: '24px'
  };

  const graphicStyle = {
    paddingRight: '0.5rem',
    width: '24px'
  };

  const mainIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    width: '24px'
  };

  const transferModeGraphic = (
    <div css={graphicStyle}>
      <div css={{ ...circleStyle, alignItems: 'flex-start' }}>
        <TransferCircle
          animation={
            journeyLeg.status == JourneyLegStatus.LIVE
              ? AnimationType.OUTSIDE
              : AnimationType.NONE
          }
        />
      </div>
      <div css={{ ...circleStyle, alignItems: 'flex-start' }}>
        <TransferCircle
          animation={
            journeyLeg.status == JourneyLegStatus.LIVE
              ? AnimationType.INSIDE
              : AnimationType.NONE
          }
        />
      </div>
      <div css={mainIconStyle}>
        <TransferTravelIcon
          travelMode={travelMode}
          color={
            journeyLeg.status == JourneyLegStatus.LIVE
              ? COLORS.lnerRed
              : '#909098'
          }
        />
      </div>
      <div css={{ ...circleStyle, alignItems: 'flex-end' }}>
        <TransferCircle
          animation={
            journeyLeg.status == JourneyLegStatus.LIVE
              ? AnimationType.INSIDE
              : AnimationType.NONE
          }
        />
      </div>
      <div css={{ ...circleStyle, alignItems: 'flex-end' }}>
        <TransferCircle
          animation={
            journeyLeg.status == JourneyLegStatus.LIVE
              ? AnimationType.OUTSIDE
              : AnimationType.NONE
          }
        />
      </div>
    </div>
  );

  //Transfer string details

  const descriptionStyle = {
    fontSize: '14px',
    lineHeight: '18px',
    color: COLORS.dune,
    paddingBottom: '0.3rem'
  };

  const changeText = (
    <div css={descriptionStyle}>
      {'Change at ' + journeyLeg.departureStation.name}
    </div>
  );

  const transferText = (
    <div css={descriptionStyle}>
      {travelTypeToString(travelMode) +
        ' from ' +
        journeyLeg.departureStation.name +
        ' to ' +
        journeyLeg.arrivalStation.name}
    </div>
  );

  const liveStatusStyle = {
    color: COLORS.lnerRed,
    fontFamily: FONTS.book.fontFamily,
    fontSize: '0.8rem',
    backgroundColor: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    padding: '0.1rem 0.5rem 0.0005rem',
    borderRadius: '0.6rem',
    marginLeft: '0.6rem'
  };

  const liveStatus = (
    <div css={liveStatusStyle}>
      <CircleInCircle />
      <div css={{ marginLeft: '0.15rem' }}>Live</div>
    </div>
  );

  return (
    <div css={containerStyle}>
      {transferModeGraphic}
      <div>
        {travelMode == TravelModeType.CHANGE ? changeText : transferText}
        <div css={durationStyle}>
          {formatMinutesToHours(journeyLeg.durationInMinutes)}
          {journeyLeg.status == JourneyLegStatus.LIVE ? liveStatus : null}
        </div>
      </div>
    </div>
  );
};

export default TransferJourneyLegView;
