import React from 'react';

import {
  JourneyLeg,
  JourneyLegStatus,
  OnTimeStatus
} from '../../models/journey-leg';

import COLORS from '../../styles/colors';
import FONTS from '../../styles/fonts';

import { formatMinutesToHours } from '../../helpers/minutes-to-hours';

import JourneyLegCallingPoints from '../live-journey/journey-leg-calling-points';
import LnerLogo from '../../icons/journey-leg-provider-lner-logo';

type TrainJourneyLegViewProps = {
  journeyLeg: JourneyLeg;
};

const TrainJourneyLegView = (props: TrainJourneyLegViewProps) => {
  const { journeyLeg } = props;

  const containerStyle = { padding: '1rem' };
  const bodyStyle = {
    paddingTop: '0.4rem',
    paddingLeft: '2rem',
    paddingBottom: '0.4rem'
  };

  //Status variants for Journey Details (in Header)

  const fontStyle = {
    fontSize: 14,
    fontFamily: FONTS.medium.fontFamily
  };

  const backgroundBubbleStyle = {
    ...fontStyle,
    borderRadius: '12px',
    backgroundClip: 'padding-box',
    padding: '4px 10px 1px'
  };

  const delayedStyle = {
    ...backgroundBubbleStyle,
    color: COLORS.dune,
    backgroundColor: '#EFA300'
  };

  const cancelledStyle = {
    ...backgroundBubbleStyle,
    backgroundColor: COLORS.dune,
    color: COLORS.white
  };

  const onTime = (
    <div css={{ ...fontStyle, color: COLORS.forestGreen }}>On Time</div>
  );

  const completed = (
    <div css={{ ...fontStyle, color: COLORS.doveGray }}>Completed</div>
  );

  const delayed = <div css={delayedStyle}>Delayed</div>;

  const cancelled = <div css={cancelledStyle}>Cancelled</div>;

  //Header Component

  const journeyDurationStyle = {
    fontFamily: FONTS.medium.fontFamily,
    paddingRight: '6px'
  };

  const formattedJourneyDuration = formatMinutesToHours(
    journeyLeg.durationInMinutes
  );

  const stopsStyle = {
    color: COLORS.dune,
    fontSize: '14px'
  };

  const numberOfStops = journeyLeg.callingPoints.length;
  const stopsString =
    numberOfStops == 0
      ? '(direct)'
      : numberOfStops == 1
      ? '(1 stop)'
      : '(' + numberOfStops + ' stops)';

  const journeyInfo = (
    <div css={{ display: 'flex', alignItems: 'baseline' }}>
      <div css={journeyDurationStyle}>{formattedJourneyDuration}</div>
      <div css={stopsStyle}>{stopsString}</div>
    </div>
  );

  const fullJourneyDetailsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '4px',
    alignItems: 'baseline'
  };

  const fullJourneyDetails = (
    <div css={fullJourneyDetailsStyle}>
      <div>{journeyInfo}</div>
      <div>
        {journeyLeg.status == JourneyLegStatus.COMPLETED
          ? completed
          : journeyLeg.onTimeStatus == OnTimeStatus.ONTIME
          ? onTime
          : journeyLeg.onTimeStatus == OnTimeStatus.DELAYED
          ? delayed
          : cancelled}
      </div>
    </div>
  );

  const headerStyle = {
    paddingTop: '4px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${COLORS.mercury}`
  };

  const providerStyle = {
    color: COLORS.doveGray,
    fontSize: '12px',
    paddingBottom: '2px'
  };

  const trainJourneyLegHeader = (
    <div css={headerStyle}>
      <div css={providerStyle}>
        {journeyLeg.provider == 'LNER' ? <LnerLogo /> : journeyLeg.provider}
      </div>
      {fullJourneyDetails}
    </div>
  );

  //Footer Component

  const footerStyle = {
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns:
      '[col1-start] 66px  [col1-end col2-start] auto [col2-end]',
    gridTemplateRows: '[row1-start] auto [row1-end row2-start] auto [row2-end]',
    justifyItems: 'start',
    alignItems: 'baseline',
    rowGap: '10px',
    borderTop: `1px solid ${COLORS.mercury}`,
    paddingTop: '1.4rem',
    paddingBottom: '0.4rem'
  };

  const seatTitleStyle = {
    color: COLORS.doveGray,
    fontSize: 12,
    gridColumn: 'col1-start / col1-end',
    gridRow: 'row1-start / row1-end'
  };

  const trainTitleStyle = {
    color: COLORS.doveGray,
    fontSize: 12,
    gridColumn: 'col1-start / col1-end',
    gridRow: 'row2-start / row2-end'
  };

  const seatInfoStyle = {
    color: COLORS.dune,
    fontSize: 14,
    gridColumn: 'col2-start / col2-end',
    gridRow: 'row1-start / row1-end'
  };

  const trainInfoStyle = {
    color: COLORS.dune,
    fontSize: 14,
    gridColumn: 'col2-start / col2-end',
    gridRow: 'row2-start / row2-end'
  };

  const trainJourneyLegFooter = (
    <div css={footerStyle}>
      {journeyLeg.seats ? <div css={seatTitleStyle}>Seats</div> : null}
      <div css={trainTitleStyle}>Train</div>
      {journeyLeg.seats ? (
        <div css={seatInfoStyle}>{journeyLeg.seats}</div>
      ) : null}
      <div css={trainInfoStyle}>{journeyLeg.train}</div>
    </div>
  );

  return (
    <div css={containerStyle}>
      {trainJourneyLegHeader}
      <div css={bodyStyle}>
        <JourneyLegCallingPoints journeyLeg={journeyLeg} />
        {trainJourneyLegFooter}
      </div>
    </div>
  );
};

export default TrainJourneyLegView;
