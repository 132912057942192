import React from 'react';
import './transfer-circle-animation.css';

export enum AnimationType {
  INSIDE,
  OUTSIDE,
  NONE
}

type TransferCircleProps = {
  animation: AnimationType;
};

const baseCss = {
  borderRadius: '50%',
  width: '2px',
  height: '2px',
  padding: '1px',
  backgroundColor: '#b6b6b6'
};

export const TransferCircle = (props: TransferCircleProps) => {
  switch (props.animation) {
    case AnimationType.INSIDE: {
      return (
        <div
          css={{
            ...baseCss,
            animation: 'colorChange 2.5s infinite',
            animationDelay: '1.25s'
          }}
        />
      );
    }

    case AnimationType.OUTSIDE: {
      return (
        <div
          css={{
            ...baseCss,
            animation: 'colorChange 2.5s infinite'
          }}
        />
      );
    }

    case AnimationType.NONE: {
      return (
        <div
          css={{
            ...baseCss
          }}
        />
      );
    }
  }
};
