import React from 'react';

import { JourneyLeg, JourneyLegType } from 'models/journey-leg';

import TrainJourneyLegView from 'components/live-journey/train-journey-leg-view';
import TransferJourneyLegView from 'components/live-journey/transfer-journey-leg-view';

type JourneyLegsProps = {
  journeyLegs: JourneyLeg[];
};

const JourneyLegs = (props: JourneyLegsProps) => {
  return (
    <>
      {props.journeyLegs.map((journeyLeg: JourneyLeg, index: number) =>
        journeyLeg.type === JourneyLegType.TRAIN ? (
          <TrainJourneyLegView key={index} journeyLeg={journeyLeg} />
        ) : (
          <TransferJourneyLegView key={index} journeyLeg={journeyLeg} />
        )
      )}
    </>
  );
};

export default JourneyLegs;
