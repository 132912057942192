import React from 'react';

import COLORS from '../../styles/colors';
import FONTS from '../../styles/fonts';

import { JourneyLeg, JourneyLegStatus } from '../../models/journey-leg';

type JourneySummaryProps = {
  isJourneyLive: boolean;
  formattedTotalDuration: string;
  journeyLegs: JourneyLeg[];
};

const JourneySummary = (props: JourneySummaryProps) => {
  const { isJourneyLive, journeyLegs, formattedTotalDuration } = props;

  const containerStyle = {
    padding: '1rem',
    borderBottom: `1px solid ${COLORS.mercury}`
  };

  const row1Style = {
    display: 'flex',
    color: COLORS.dune,
    fontSize: 14,
    justifyContent: 'space-between'
  };

  const row2Style = {
    display: 'flex',
    color: COLORS.dune,
    fontSize: 16,
    fontFamily: FONTS.medium.fontFamily,
    paddingTop: '4px',
    justifyContent: 'space-between'
  };

  const delayedStyle = {
    textDecorationLine: 'line-through',
    paddingRight: '4px',
    color: COLORS.doveGray,
    fontFamily: FONTS.book.fontFamily
  };

  const row3Style = {
    color: COLORS.doveGray,
    fontSize: 12,
    paddingTop: '10px'
  };

  const numberOfLegs = journeyLegs.length;
  const endStation = journeyLegs[numberOfLegs - 1].arrivalStation;
  const startStation = journeyLegs[0].departureStation;

  //Row1 Component

  const row1RightString =
    journeyLegs[numberOfLegs - 1].status == JourneyLegStatus.COMPLETED
      ? 'Arrived'
      : isJourneyLive
      ? 'Arrives'
      : journeyLegs[0].dateString;

  const Row1 = (
    <div css={row1Style}>
      <div>{startStation.name} to</div>
      <div>{row1RightString}</div>
    </div>
  );

  //Row 2 Component

  const delayedDisplay = (
    <div css={{ display: 'flex' }}>
      <div css={delayedStyle}>{endStation.expectedTime}</div>
      <div>{endStation.actualTime}</div>
    </div>
  );

  const ArrivalTime = (
    <div>
      {endStation.actualTime ? delayedDisplay : endStation.expectedTime}
    </div>
  );

  const row2RightDiv = isJourneyLive ? ArrivalTime : startStation.expectedTime;

  const Row2 = (
    <div css={row2Style}>
      <div>{endStation.name}</div>
      {row2RightDiv}
    </div>
  );

  //Row 3 Component

  const numberOfLegsString =
    numberOfLegs == 1 ? 'direct' : numberOfLegs - 1 + ' changes';

  const Row3 = (
    <div css={row3Style}>
      {formattedTotalDuration}, {numberOfLegsString}
    </div>
  );

  return (
    <div css={containerStyle}>
      {Row1}
      {Row2}
      {Row3}
    </div>
  );
};

export default JourneySummary;
