import TicketInfoArrowRight from '../../icons/arrow-right';
import LiveIcon from '../../icons/live-journey/live-icon';
import {
  JourneyLeg,
  JourneyLegStatus,
  Station
} from '../../models/journey-leg';
import React, { useState } from 'react';
import COLORS from '../../styles/colors';
import FONTS from '../../styles/fonts';

type JourneyLegCallingPointsProps = {
  journeyLeg: JourneyLeg;
};

type JourneyLegCallingPointProps = {
  type: StationType;
  station: Station;
  departurePlatform: string;
};

type JourneyLegMidLegCallingPointsProps = {
  callingPoints: Station[];
};

type JourneyLegCallingPointAccordianProps = {
  isLegLive: boolean;
  isLegExpanded: boolean;
  callingPointsLength: number;
};

enum StationType {
  DEPARTURE,
  MIDLEG,
  ARRIVAL
}

const JourneyLegCallingPoints = (props: JourneyLegCallingPointsProps) => {
  const { journeyLeg } = props;
  const isLegLive = props.journeyLeg.status == JourneyLegStatus.LIVE;
  const [isLegExpanded, setIsLegExpanded] = useState(false);

  return (
    <div>
      <JourneyLegCallingPoint
        type={StationType.DEPARTURE}
        station={journeyLeg.departureStation}
        departurePlatform={journeyLeg.departurePlatform}
      />

      <div onClick={() => setIsLegExpanded(!isLegExpanded)}>
        <JourneyLegCallingPointAccordian
          callingPointsLength={journeyLeg.callingPoints.length}
          isLegLive={isLegLive}
          isLegExpanded={isLegExpanded}
        />
      </div>

      <div>
        {isLegExpanded ? (
          <JourneyLegMidLegCallingPoints
            callingPoints={journeyLeg.callingPoints}
          />
        ) : null}
      </div>

      <JourneyLegCallingPoint
        type={StationType.ARRIVAL}
        station={journeyLeg.arrivalStation}
        departurePlatform=""
      />
    </div>
  );
};

const JourneyLegCallingPointAccordian = (
  props: JourneyLegCallingPointAccordianProps
) => {
  const accordianStyle = {
    padding: '0.8rem 1rem',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.lnerRed,
    fontSize: '0.9rem',
    lineHeight: '1.1rem',
    cursor: 'pointer'
  };
  const lineStyle = { width: '2rem' };
  const liveStyle = { width: '4rem' };
  const arrowStyle = {
    width: '1.5rem',
    transform: props.isLegExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'
  };
  const textStyle = { flexGrow: 1 };
  const accordianText = props.isLegExpanded
    ? 'Hide all calling points'
    : 'See all calling points';

  const accordianHtml = (
    <div css={accordianStyle}>
      <div css={lineStyle}></div>
      {props.isLegLive ? (
        <div css={liveStyle}>
          <LiveStatus />
        </div>
      ) : null}
      <div css={textStyle}>{accordianText}</div>
      <div css={arrowStyle}>
        <TicketInfoArrowRight />
      </div>
    </div>
  );
  return props.callingPointsLength != 0 ? accordianHtml : <div></div>;
};

const LiveStatus = () => {
  const LiveStatusStyle = {
    color: COLORS.lnerRed,
    fontFamily: FONTS.book.fontFamily,
    fontSize: '0.8rem',
    backgroundColor: COLORS.wildSand,
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    borderRadius: '0.6rem',
    marginRight: '0.9rem'
  };

  return (
    <div css={LiveStatusStyle}>
      <LiveIcon />
      Live
    </div>
  );
};

// TODO LNER2INT21-48: Add vertical lines (and logic)
const JourneyLegCallingPoint = (props: JourneyLegCallingPointProps) => {
  const containerStyle = {
    padding: '1.1rem 1rem',
    display: 'grid',
    gridTemplateColumns: '2rem 4rem auto',
    gridTemplateRows: 'auto auto',
    gap: '0.2rem 0',
    color: COLORS.doveGray,
    fontFamily: FONTS.book.fontFamily,
    fontSize: '0.8rem',
    lineHeight: '1.0rem'
  };

  const timeStyle = {
    color: COLORS.dune,
    fontFamily: FONTS.bold.fontFamily,
    fontSize: '0.9rem',
    lineHeight: '1.1rem'
  };

  const stationNameStyle = {
    color: COLORS.dune,
    fontSize: '0.9rem',
    lineHeight: '1.1rem'
  };

  const lineStyle = {
    gridColumn: '1',
    gridRow: '1 / 3'
  };

  var callingPointTypeText: string;
  switch (props.type) {
    case StationType.DEPARTURE: {
      callingPointTypeText = 'Depart';
      break;
    }
    case StationType.ARRIVAL: {
      callingPointTypeText = 'Arrive';
      break;
    }
    default: {
      callingPointTypeText = '';
      break;
    }
  }

  return (
    <div css={containerStyle}>
      <div css={lineStyle}></div>
      <div css={timeStyle}>{props.station.expectedTime}</div>
      <div css={stationNameStyle}>{props.station.name}</div>
      <div>{callingPointTypeText}</div>
      <div>{props.departurePlatform}</div>
    </div>
  );
};

const JourneyLegMidLegCallingPoints = (
  props: JourneyLegMidLegCallingPointsProps
) => (
  <>
    {' '}
    {props.callingPoints.map((callingPoint) => {
      return (
        <JourneyLegCallingPoint
          type={StationType.MIDLEG}
          station={callingPoint}
          departurePlatform=""
        />
      );
    })}{' '}
  </>
);

export default JourneyLegCallingPoints;
