import {
  JourneyLeg,
  JourneyLegType,
  JourneyLegStatus,
  OnTimeStatus,
  TravelModeType
} from '../models/journey-leg';

const callingPoint = (name: string, time: string): any => {
  return { name: name, expectedTime: time, expectedDate: 'Wed 15 Sep' };
};

const liveJourneyMockData: JourneyLeg[] = [
  {
    status: JourneyLegStatus.LIVE,
    type: JourneyLegType.TRAIN,
    onTimeStatus: OnTimeStatus.ONTIME,
    dateString: 'Wed 15 Sep',
    durationInMinutes: 280,
    callingPoints: [
      callingPoint('Newcastle', '15:32'),
      callingPoint('Durham', '16:01'),
      callingPoint('Darlington', '16:18'),
      callingPoint('Northallerton', '16:35'),
      callingPoint('York', '16:49'),
      callingPoint('Doncaster', '17:04'),
      callingPoint('Newark North Gate', '17:45'),
      callingPoint('Peterborough', '17:45'),
      callingPoint('Stevenage', '17:45')
    ],
    departureStation: callingPoint('Edinburgh', '09:00'),
    departurePlatform: 'Platform 2',
    arrivalStation: callingPoint('London Kings Cross', '13:40'),
    seats: 'Coach C - 51 & 52',
    train: '09:00 to Brighton',
    provider: 'LNER',
    travelMode: TravelModeType.TRAIN
  },
  {
    status: JourneyLegStatus.FUTURE,
    type: JourneyLegType.TRANSFER,
    onTimeStatus: OnTimeStatus.ONTIME,
    dateString: 'Wed 15 Sep',
    durationInMinutes: 40,
    callingPoints: [],
    departureStation: callingPoint('London Kings Cross', '13:40'),
    departurePlatform: 'xx',
    arrivalStation: callingPoint('London Euston', '14:20'),
    seats: 'xx',
    train: 'xx',
    provider: 'xx',
    travelMode: TravelModeType.UNDERGROUND
  },
  {
    status: JourneyLegStatus.FUTURE,
    type: JourneyLegType.TRAIN,
    onTimeStatus: OnTimeStatus.ONTIME,
    dateString: 'Wed 15 Sep',
    durationInMinutes: 15,
    callingPoints: [],
    departureStation: callingPoint('London Euston', '14:24'),
    departurePlatform: 'Platform 3',
    arrivalStation: callingPoint('Watford Junction', '14:39'),
    seats: 'Coach C - 51 & 52',
    train: '14:24 to Birmingham New Street',
    provider: 'West Midlands Trains',
    travelMode: TravelModeType.TRAIN
  }
];

export default liveJourneyMockData;
