import { TravelModeType } from '../models/journey-leg';

//provides SVG paths for transfer-leg-travel-icon and calling-points-travel-icon

export const getPathFromTravelMode = (travelMode: TravelModeType): string => {
  switch (travelMode) {
    case TravelModeType.BUS:
      return 'M5.063,2.89c4.198,-1.187 9.65,-1.187 13.874,0 0.545,0.184 1.063,0.474 1.063,1.054v15.262c0,0.553 -0.49,1.028 -1.063,1.028h-0.545v0.738c0,0.58 -0.464,1.028 -1.063,1.028h-0.9c-0.6,0 -1.09,-0.448 -1.09,-1.028v-0.738L8.634,20.234v0.738c0,0.58 -0.464,1.028 -1.063,1.028h-0.9c-0.6,0 -1.063,-0.448 -1.063,-1.028v-0.738h-0.545c-0.572,0 -1.063,-0.475 -1.063,-1.028L4,3.944c0,-0.58 0.518,-0.87 1.063,-1.054zM9.362,16.724l0.5,1c0.084,0.169 0.258,0.276 0.447,0.276h3.382c0.19,0 0.363,-0.107 0.447,-0.276l0.5,-1c0.124,-0.247 0.024,-0.548 -0.223,-0.671 -0.07,-0.035 -0.146,-0.053 -0.224,-0.053L9.809,16c-0.276,0 -0.5,0.224 -0.5,0.5 0,0.078 0.018,0.154 0.053,0.224zM8,4.5c0,0.276 0.224,0.5 0.5,0.5h7c0.276,0 0.5,-0.224 0.5,-0.5s-0.224,-0.5 -0.5,-0.5h-7c-0.276,0 -0.5,0.224 -0.5,0.5zM6,6h12v8L6,14L6,6zM17.011,16C16.46,16 16,16.442 16,17s0.46,1 1.011,1c0.53,0 0.989,-0.442 0.989,-1s-0.46,-1 -0.989,-1zM6.99,16C7.54,16 8,16.442 8,17s-0.46,1 -1.011,1C6.459,18 6,17.558 6,17s0.46,-1 0.989,-1z';
    case TravelModeType.FERRY:
      return 'M19.364,20.182c-1.264,0 -2.528,-0.427 -3.637,-1.205 -2.218,1.555 -5.054,1.555 -7.272,0 -1.11,0.778 -2.373,1.205 -3.637,1.205H3V22h1.818c1.25,0 2.491,-0.314 3.637,-0.905 2.29,1.178 4.981,1.178 7.272,0 1.146,0.587 2.387,0.905 3.637,0.905h1.818v-1.818h-1.818zM4.768,18.364h0.05c1.455,0 2.75,-0.8 3.637,-1.819 0.886,1.019 2.181,1.819 3.636,1.819 1.454,0 2.75,-0.8 3.636,-1.819 0.891,1.019 2.178,1.819 3.637,1.819h0.05l1.722,-6.078c0.078,-0.231 0.055,-0.486 -0.054,-0.704 -0.114,-0.218 -0.31,-0.382 -0.546,-0.45l-1.172,-0.387v-4.2c0,-1.004 -0.814,-1.818 -1.819,-1.818h-2.727V2H9.364v2.727H6.636c-1.004,0 -1.818,0.814 -1.818,1.818v4.2l-1.168,0.382c-0.236,0.073 -0.432,0.232 -0.545,0.45 -0.114,0.218 -0.132,0.473 -0.055,0.705l1.718,6.082zM6.636,6.545h10.91v3.605L12.09,8.364 6.636,10.15V6.545z';
    case TravelModeType.HOVERCRAFT:
      return 'M16.49,15.977c1.343,0.778 2.872,1.205 4.4,1.205h2.2L23.09,19h-2.2c-1.512,0 -3.013,-0.318 -4.4,-0.905 -2.771,1.178 -6.027,1.178 -8.8,0 -1.385,0.591 -2.887,0.905 -4.4,0.905h-2.2v-1.818h2.2c1.53,0 3.059,-0.427 4.4,-1.205 2.685,1.555 6.117,1.555 8.8,0zM20.905,11.286c0.053,0 0.106,0.002 0.158,0.006h0.017l0.133,0.016 0.046,0.008c0.035,0.006 0.07,0.012 0.105,0.02l0.057,0.014 0.083,0.023 0.067,0.021 0.075,0.027 0.068,0.027c0.027,0.01 0.053,0.022 0.078,0.034l0.032,0.016c0.04,0.019 0.078,0.04 0.116,0.06l0.013,0.009 0.091,0.055 0.055,0.037c0.025,0.017 0.05,0.035 0.073,0.053l0.035,0.028c0.045,0.035 0.088,0.072 0.129,0.11l0.094,0.095c0.353,0.375 0.57,0.88 0.57,1.436 0,0.766 -0.411,1.436 -1.025,1.802l-1.084,-0.001c-1.53,0 -3.058,-0.427 -4.4,-1.205 -2.684,1.555 -6.116,1.555 -8.8,0 -1.342,0.778 -2.871,1.205 -4.4,1.205L2.025,15.182C1.411,14.818 1,14.148 1,13.382c0,-0.556 0.217,-1.061 0.57,-1.436l0.008,-0.008 0.086,-0.086c0.041,-0.039 0.084,-0.076 0.129,-0.111 0.011,-0.01 0.023,-0.018 0.035,-0.027 0.024,-0.019 0.048,-0.037 0.073,-0.054l0.056,-0.037c0.03,-0.02 0.06,-0.038 0.09,-0.056l0.013,-0.007c0.038,-0.022 0.077,-0.042 0.116,-0.061l0.032,-0.015c0.025,-0.013 0.051,-0.024 0.078,-0.035 0.022,-0.01 0.045,-0.019 0.068,-0.027 0.024,-0.01 0.05,-0.019 0.075,-0.027l0.067,-0.021 0.083,-0.023 0.057,-0.014c0.035,-0.008 0.07,-0.014 0.105,-0.02l0.045,-0.008 0.134,-0.015 0.02,-0.002 0.155,-0.005h17.81zM16.365,5.698c0.68,0.002 1.31,0.343 1.686,0.9l0.088,0.145 2.216,3.844L5.54,10.587L5.54,6.439h1.747c0.15,0 0.281,0.097 0.329,0.235l0.016,0.072 0.102,0.698h5.832L13.566,6.048c0,-0.17 0.12,-0.31 0.28,-0.342l0.07,-0.008h2.45zM3.841,5c0.553,0 1,0.448 1,1v3.587c0,0.553 -0.447,1 -1,1h-0.095c-0.552,0 -1,-0.447 -1,-1L2.746,6c0,-0.552 0.448,-1 1,-1h0.095zM16.212,7.095h-1.244v1.497c0,0.118 0.082,0.217 0.192,0.243l0.057,0.006h2.545l-0.286,-0.499 -0.433,-0.75c-0.166,-0.306 -0.484,-0.496 -0.83,-0.497z';
    case TravelModeType.TRAM:
      return 'M18.526,20.386c0.81,-0.556 1.461,-1.612 1.461,-2.619L19.987,8.354c0.006,-0.868 -0.326,-1.643 -0.986,-2.304 -1.09,-1.091 -3.03,-1.805 -5.32,-1.988L13.681,2.347h0.029c1.412,0 2.607,0.456 3.652,1.396 0.483,0.434 1.229,0.396 1.665,-0.085 0.437,-0.48 0.399,-1.223 -0.084,-1.657C17.465,0.673 15.704,0 13.71,0h-2.368C9.347,0 7.586,0.673 6.109,2.001c-0.483,0.434 -0.521,1.177 -0.085,1.657 0.437,0.481 1.183,0.519 1.666,0.085 1.04,-0.935 2.228,-1.391 3.632,-1.395v1.715c-2.29,0.183 -4.23,0.896 -5.32,1.987 -0.66,0.66 -0.991,1.436 -0.986,2.304v9.413c0,0.976 0.627,2.054 1.463,2.607L5,24h2.546l1.303,-3.195h7.303L17.455,24L20,24l-1.474,-3.614zM9.536,17.95c-0.24,0.276 -0.585,0.422 -0.997,0.422 -0.842,0 -1.226,-0.705 -1.226,-1.36 0,-0.655 0.384,-1.36 1.226,-1.36 0.914,0 1.33,0.705 1.33,1.36 0,0.359 -0.117,0.692 -0.333,0.938zM17.372,17.88c-0.236,0.276 -0.574,0.422 -0.978,0.422 -0.86,0 -1.252,-0.705 -1.252,-1.36 0,-0.36 0.112,-0.694 0.316,-0.94 0.226,-0.275 0.55,-0.42 0.936,-0.42 0.897,0 1.305,0.704 1.305,1.36 0,0.358 -0.116,0.692 -0.327,0.938zM17.708,12.182c0,1.133 0,1.307 -0.749,1.307L8.08,13.489c-0.643,0 -0.748,-0.183 -0.748,-1.307L7.332,8.564c0,-1.305 2.616,-1.988 5.2,-1.988 2.56,0 5.161,0.671 5.178,1.953v3.653z';
    case TravelModeType.WALKING:
      return 'M11.251,6.707c-0.348,0 -0.697,0.105 -1.46,0.502 -1.313,0.691 -2.4,1.718 -3.14,2.965l-0.064,0.11 -0.48,0.837c-0.24,0.46 -0.065,1.025 0.414,1.276 0.13,0.063 0.305,0.105 0.458,0.105 0.311,0 0.642,-0.152 0.824,-0.437l0.026,-0.044 0.48,-0.837c0.348,-0.586 0.784,-1.088 1.286,-1.527l-0.021,0.203 -0.023,0.208c-0.063,0.555 -0.14,1.124 -0.174,1.493l-0.016,0.132 -0.063,0.46c-0.083,0.624 -0.106,1.118 0.34,1.562l0.757,0.714 2.339,2.194c0.285,0.274 0.475,0.584 0.587,0.947l0.023,0.079 0.872,3.493c0.13,0.502 0.61,0.858 1.134,0.858 0.087,0 0.196,-0.02 0.283,-0.042 0.616,-0.143 1.005,-0.723 0.882,-1.314l-0.01,-0.046 -0.894,-3.472c-0.188,-0.784 -0.597,-1.47 -1.17,-2.043l-0.072,-0.07 -1.526,-1.423 0.785,-3.577c0.492,0.59 1.08,1.107 1.73,1.532l0.122,0.078 1.003,0.628c0.458,0.293 1.047,0.167 1.352,-0.272 0.296,-0.426 0.182,-0.97 -0.243,-1.27l-0.04,-0.027 -1.003,-0.628c-0.567,-0.355 -1.047,-0.795 -1.46,-1.297l-0.236,-0.287 -0.172,-0.206c-1.028,-1.223 -1.365,-1.421 -1.774,-1.45l-0.042,-0.002 -1.614,-0.105zM8.866,14.301l-0.24,1.444c-0.038,0.242 -0.112,0.483 -0.22,0.725l-0.041,0.09 -1.81,3.745c-0.261,0.544 -0.021,1.214 0.567,1.486 0.175,0.063 0.327,0.104 0.502,0.104 0.44,0 0.84,-0.232 1.044,-0.603l0.024,-0.045 1.831,-3.724c0.196,-0.418 0.327,-0.837 0.414,-1.297 -0.13,-0.105 -0.283,-0.251 -0.414,-0.377L8.91,14.343l-0.044,-0.042zM12.326,2.011c-1.098,-0.119 -2.085,0.675 -2.204,1.773 -0.12,1.098 0.673,2.085 1.771,2.204 1.098,0.12 2.085,-0.674 2.205,-1.772 0.12,-1.098 -0.674,-2.085 -1.772,-2.204z';
    case TravelModeType.TAXI:
      return 'M21.933,11.172c-0.362,-0.399 -0.836,-0.635 -1.421,-0.71l-1.815,-4.907h-0.001c-0.111,-0.302 -0.269,-0.567 -0.475,-0.795 -0.418,-0.46 -0.983,-0.704 -1.695,-0.732v-0.003h-2.363L14.163,2.812c0,-0.253 -0.07,-0.452 -0.211,-0.596 -0.14,-0.144 -0.33,-0.216 -0.57,-0.216L10.218,2c-0.239,0 -0.429,0.072 -0.57,0.216 -0.14,0.144 -0.21,0.343 -0.21,0.596v1.213L7.217,4.025c-0.78,0 -1.392,0.245 -1.837,0.735 -0.206,0.228 -0.365,0.493 -0.475,0.795L3.087,10.463c-0.585,0.074 -1.059,0.31 -1.421,0.71C1.222,11.662 1,12.324 1,13.16v5.715h2.025L3.025,21.438c0,0.254 0.07,0.452 0.211,0.596 0.14,0.144 0.33,0.216 0.57,0.216L4.945,22.25c0.239,0 0.429,-0.072 0.57,-0.216 0.14,-0.144 0.21,-0.343 0.21,-0.596v-2.562h12.15v2.562c0,0.254 0.07,0.452 0.211,0.596 0.14,0.144 0.33,0.216 0.57,0.216L19.795,22.25c0.239,0 0.429,-0.072 0.57,-0.216 0.14,-0.144 0.21,-0.343 0.21,-0.596v-2.562L22.6,18.876L22.6,13.158c0,-0.834 -0.223,-1.497 -0.667,-1.987zM6.21,6.812c0.08,-0.218 0.194,-0.408 0.342,-0.572 0.32,-0.352 0.759,-0.527 1.32,-0.527L15.827,5.713v0.002c0.512,0.02 0.918,0.195 1.218,0.525 0.148,0.164 0.261,0.355 0.34,0.571h0.001l1.293,3.626L4.917,10.437L6.21,6.812zM5.54,14.903c-0.284,0.173 -0.681,0.26 -1.193,0.26s-0.89,-0.087 -1.137,-0.26c-0.124,-0.283 -0.185,-0.764 -0.185,-1.09 0,-0.327 0.061,-0.808 0.185,-1.092 0.32,-0.173 0.718,-0.259 1.193,-0.259 0.474,0 0.853,0.086 1.137,0.259 0.123,0.284 0.185,0.765 0.185,1.091 0,0.327 -0.062,0.808 -0.185,1.091zM20.39,14.903c-0.284,0.173 -0.682,0.26 -1.193,0.26 -0.512,0 -0.89,-0.087 -1.137,-0.26 -0.124,-0.283 -0.185,-0.764 -0.185,-1.09 0,-0.327 0.062,-0.808 0.185,-1.092 0.32,-0.173 0.718,-0.259 1.193,-0.259 0.474,0 0.853,0.086 1.137,0.259 0.123,0.284 0.185,0.765 0.185,1.091 0,0.327 -0.062,0.808 -0.185,1.091z';
    case TravelModeType.UNDERGROUND:
      return 'M7.555,16.21c1.057,1.286 2.656,2.106 4.445,2.106 1.79,0 3.388,-0.82 4.445,-2.105h4.244C19.259,19.613 15.907,22 12,22s-7.259,-2.387 -8.689,-5.79zM23,9.633v4.736L1,14.369L1,9.632h22zM12,2c3.907,0 7.259,2.387 8.689,5.79h-4.244C15.388,6.503 13.789,5.683 12,5.683c-1.79,0 -3.388,0.82 -4.445,2.105L3.311,7.788C4.741,4.387 8.093,2 12,2z';
    case TravelModeType.CHANGE:
      return 'M13.196,13.26l0.404,0.54c0.526,0.701 1.33,1.135 2.199,1.193L16,15h2v-1c0,-0.276 0.224,-0.5 0.5,-0.5 0.108,0 0.213,0.035 0.3,0.1l2.667,2c0.22,0.166 0.265,0.48 0.1,0.7 -0.029,0.038 -0.062,0.072 -0.1,0.1l-2.667,2c-0.22,0.166 -0.534,0.12 -0.7,-0.1 -0.065,-0.087 -0.1,-0.192 -0.1,-0.3v-1h-2c-1.491,0 -2.9,-0.665 -3.847,-1.806L12,15l-0.065,-0.087 1.261,-1.653zM18.5,5.5c0.108,0 0.213,0.035 0.3,0.1l2.667,2c0.22,0.166 0.265,0.48 0.1,0.7 -0.029,0.038 -0.062,0.072 -0.1,0.1l-2.667,2c-0.22,0.166 -0.534,0.12 -0.7,-0.1 -0.065,-0.087 -0.1,-0.192 -0.1,-0.3V9h-2c-0.877,0 -1.706,0.383 -2.274,1.043l-0.126,0.157L10,15c-0.895,1.193 -2.272,1.92 -3.752,1.994L6,17H3v-2h3c0.877,0 1.706,-0.383 2.274,-1.043L8.4,13.8 12,9c0.895,-1.193 2.272,-1.92 3.752,-1.994L16,7h2V6c0,-0.276 0.224,-0.5 0.5,-0.5zM6,7c1.491,0 2.9,0.665 3.847,1.806L10,9l0.079,0.105 -1.261,1.653L8.4,10.2c-0.526,-0.701 -1.33,-1.135 -2.199,-1.193L6,9H3V7h3z';
    default:
      // TRAIN
      return 'M21.91 15.063c-0.741-3.75-1.463-7.502-2.187-11.255-0.312-1.62-1.68-2.799-3.37-2.808H7.647c-1.688 0.01 -3.056 1.189-3.369 2.808-0.724 3.753-1.446 7.505-2.187 11.255-0.544 2.753 1.44 5.127 4.32 5.132 1.863 0.003 3.727 0 5.59 0s3.727 0.003 5.59 0c2.88-0.005 4.864-2.379 4.32-5.132zM9.359 2.713h5.282c0.428 0 0.775 0.338 0.775 0.754 a0.765 0.765 0 0 1-0.775 0.753 H9.359a0.765 0.765 0 0 1-0.775-0.753c0-0.416 0.347 -0.753 0.775 -0.753zM6.26 17.874c-0.86 0.009 -1.537-0.635-1.539-1.463-0.002-0.837 0.656 -1.487 1.511-1.49 0.838 -0.003 1.513 0.637 1.525 1.447 0.013 0.832-0.65 1.5-1.497 1.507zm0.09-4.656c-0.764 0-1.125-0.69-0.985-1.417 0.254 -1.306 0.53 -2.609 0.798 -3.913 0.076 -0.37 0.154 -0.741 0.24 -1.11 0.127 -0.55 0.411 -0.76 0.997 -0.78h9.2c0.586 0.02 0.87 0.23 0.998 0.78 0.086 0.369 0.163 0.74 0.239 1.11 0.268 1.304 0.544 2.607 0.798 3.913 0.14 0.727-0.22 1.417-0.986 1.417H6.351zm11.39 4.657c-0.847-0.007-1.51-0.675-1.497-1.507 0.012 -0.81 0.687 -1.45 1.525-1.447 0.855 0.003 1.513 0.653 1.51 1.49-0.001 0.828 -0.678 1.472-1.538 1.464zM5.312 22h13.376v-1.004H5.312V22z';
  }
};
